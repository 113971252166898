@import 'styles/vars';

.info {
    display: inline-block;
    background: rgba(0,0,0,0.6);
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    color: $primary-white;
    font-family: arial;
    font-weight: bold;
    text-transform: lowercase;
    font-size: 1.1rem;
    border-radius: 3px;
    position: relative;
    top: -5px;
    right: -5px;
}

.info:hover {
    color: $primary-white;
    background: rgba(0,0,0,0.7);
}