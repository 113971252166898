@import 'styles/vars';

.button {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}

.button__addRecord {
    outline: none;
    appearance: none;
    border: none;
    background: none;
    color: lighten($primary-blue,20);
    line-height: 24px;
    text-decoration: underline;
    padding: 1rem;
    margin: 1rem 0;
    cursor: pointer;
    i {
        vertical-align: middle;
        margin-right: .5rem;
    }
}

.button__addRecord:hover {
    color: #666;
}

.button__or {
    margin-left: 2rem;
    margin-right: 1rem;
    color: rgba(0,0,0,0.7);
}

.button__cancel {
    text-decoration: underline;
    // color: $secondary-blue;
    letter-spacing: 0.02em;
}

.button__cancel:hover {
    color: rgba(0,0,0,0.4);
}

.button__primary {
    background: $secondary-blue;
    color: $primary-white;
    padding: 1.2rem 3rem;
    border-radius: 4px;
    font-size: 1.6rem;
}

.button__primary:hover {
    background: darken($secondary-blue, 10);
    color: $primary-white;
}

.button__primary:disabled {
    background: $read-only-gray;
}

.button__secondary {
    border: 1px solid rgba(0,0,0,0.1);
    color: $primary-black;
    padding: 1rem 1.5rem;
    border-radius: 4px;
    font-size: 1.4rem;
    letter-spacing: 0.02em;
}

.button__secondary:hover {
    background: rgba(0,0,0,0.05);
}

.button__cta {
    background: $success-green;
    color: $primary-white;
    padding: 1.2rem 3rem;
    border-radius: 4px;
    font-size: 1.6rem;
}

.button__destroy {
    background: $primary-red;
    color: $primary-white;
    padding: 1.2rem 3rem;
    border-radius: 4px;
    font-size: 1.6rem;
}

.button__destroy:hover {
    background: darken($primary-red, 10);
}

.button__logout {

    line-height: 24px;
    color: $secondary-blue;
    float: right;
    margin-top: -5rem;
    i {
        vertical-align: middle;
    }

}

.button__logout:hover {
    color: $primary-blue;
}