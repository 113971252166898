@import 'styles/vars';

.header {
    position: relative;

    .menu {
        flex-direction: column;
        justify-content: space-between;
    }

    a {
        display: block;
        color: lighten($primary-blue, 40);
        text-align: center;
        text-decoration: none;
        padding: 2rem 0;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 0.02em;
        font-weight: bold;
        line-height: 24px;
        i {
            vertical-align: bottom;
        }
    }

    a.active {

        color: $primary-white;
        background: lighten($primary-blue, 10);

    }

    a:hover {
        color: lighten($primary-blue, 40);
        background: rgba(0,0,0,0.2);
    }

    .userLink { 
        width: 100%;
        i {
            font-size: 48px;
        }
        .initials {
            background: #fff;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            margin: 0 auto;
            color: $secondary-blue;
            font-size: 2rem;
            line-height: 48px;
            font-weight: normal;
        }
    }

    .versionLink {
        width: 100%;
        position: relative;
        text-align: center;
        .versionNotification {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: red;
            color: #fff;
            position: absolute;
            right: .5rem;
            top: 1rem;
            text-align: center;
            line-height: 12px;
        }
    }

    .chatLink {
        position: relative;
        .unreadMessagesNotification {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: red;
            color: #fff;
            position: absolute;
            right: 2rem;
            top: 1.2rem;
            text-align: center;
            line-height: 16px;
        }
    }

}

@media screen and (min-width:64em) {
    .header {

        height: 100%;

        .hamburgerMenu {
            display: none !important;
        }

        a i {
            display: block;
            margin-bottom: .7rem;
        }

        // .userLink {
            // position: absolute;
            // left: 50%;
            // transform: translateX(-50%);
            // bottom: -1rem;
        // }

        // .versionLink {
        //     position: absolute;
        //     bottom: 8rem;
        // }

        .fixed {
            height: 100%;
        }

        .menu {
            position: fixed;
            display: flex;
            height: 100%;
            width: 8rem;
        }

    }

}
@media screen and (max-width:63.999em) {
    .menu {
        display: none;
    }
    .menu--expanded {
        display: flex;
        height: calc(100% - 44px);
    }
    .header {
        a {
            i {
                margin-right: 1rem;
            }
        }
        .fixed {
            // height: calc(100% - 44px);
            height: 100%;
            // position: fixed;
        }
    }

    .header--expanded {
        height: 100%;
        position: fixed;
        z-index: 1;
        width: 100%;
        overflow-y: auto;
    }
}