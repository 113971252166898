@import 'styles/vars';

.StripeElement {
    height: 40px;
    padding: 10px 13px;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    // background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: normal;
    box-sizing: border-box;
    margin-bottom: 1rem;
  
    // box-shadow: 0 1px 3px 0 #e6ebf1;
    // -webkit-transition: box-shadow 150ms ease;
    // transition: box-shadow 150ms ease;
  }

  .StripeElement::placeholder {
    font-size: 1.5rem;

  }
  
  .StripeElement--focus {
    border-color: lighten($primary-blue,20);
  }
  
  .StripeElement--invalid {
    border-color: lighten(red, 30);
    background: lighten(red, 45);
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }