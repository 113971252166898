@import 'styles/vars';

.bodyContainer.bodyContainer--chat {
    max-width: initial;
    height: 100vh;
    overflow: hidden;
    padding: 0;
}

.chatContainer {
    display: grid;
    grid-template-columns: 320px auto;
    // gap: 2rem;
    height: 80vh;
    .chatContactContainer {
        border-right: 1px solid #eee;
        padding: 2rem;

        .chatContact {
            padding: 1rem;
            font-size: 1.4rem;
            border-radius: 4px;
            display: grid;
            grid-template-columns: 36px 213px;
            gap: 1rem;
            text-decoration: none;
            color: $primary-black;
            .chatHead {
                display: inline-block;
                width: 36px;
                height: 36px;
                background: #ccc;
                border-radius: 50%;
                line-height: 36px;
                text-align: center;
                margin-right: 1rem;
                color: #fff;
                font-size: 2rem;
            }
            .chatContact__messagePreview {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.2rem;
                color: rgba(0,0,0,0.6);
                margin-top: .5rem;
            }
        }
    
        .chatContact--unread .chatContact__name,
        .chatContact--unread .chatContact__messagePreview {
            font-weight: bold;
        }

        .chatContact--selected {
            background: #fafafa;
        }

    }

    // .chatMessage {
    //     display: flex;
    // }

    .chatWindowContainer {
        // position: relative;
        // overflow-y: scroll;
        // height: 100%;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
    }

    .contactContainer {
        padding: 2rem;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        position: relative;
        z-index: 1;
        font-size: 2rem;
        color: $primary-blue;
    }

    .chatHistoryContainer {
        position: relative;
        overflow-y: scroll;
        height: 78vh;
        padding: 2rem;
    }

    .chatMessageContainer {
        margin: 0 auto;
        max-width: 975px;
    }

    .chatMessageBubble {
        white-space: pre-line;
        background: rgb(241, 243, 244);
        display: inline-block;
        padding: 1.2rem;
        margin: .1rem;
        border-radius: 4px;
        font-size: 1.4rem;
        max-width: 60%;
        max-height: 80vh;
        text-overflow: ellipsis;
        overflow: hidden;

        .mediaMessage {
            max-width: 100%;
            max-height: 80vh;
            border-radius: 12px;
        }

        .mediaMessage--error {
            font-weight: bold;
        }

    }

    .chatMessage {
        position: relative;
        .chatMessageBubble {
            border-radius: 20px;
        }
        .chatMessageBubble--status {
            position: absolute;
            bottom: -12px;
            right: 0px;
            font-size: 1.1rem;
        }
        .chatMessage--isAutomated {
            position: absolute;
            right: -2px;
            top: -2px;
            i {
                font-size: 18px;
                color: #999;
            }
        }
    }

    .chatMessage--sent {
        text-align: right;
        .chatMessageBubble {
            border-radius: 20px;
            background: lighten($primary-blue, 65);
        }
    }

    .newMessageContainer {
        box-shadow: 0 1px 5px 0 rgba(60,64,67,0.15),0 4px 4px 0 rgba(60,64,67,0.1),0 -0.1px 3px 0 rgba(60,64,67,0.08);
        // position: absolute;
        // left: 1rem;
        // right: 1rem;
        // bottom: 10px;
        position: relative;
        min-height: 50px;
        border-radius: 25px;
        margin: 0 2rem 2rem 2rem;

        label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 2rem;
            color: rgba(0,0,0,0.6);
        }

        textarea {
            border: none;
            outline: none;
            appearance: none;
            background: none;
            position: relative;
            width: 100%;
            box-sizing: border-box;
            display: block;
            margin: 0;
            padding: 1.8rem 5rem 1.8rem 2rem;
            min-height: 50px;
            resize: none;
            font-family: sans-serif;
        }

        .sendMessageButton {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            outline: none;
            border: none;
            appearance: none;
            background: none;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: bold;
            color: $secondary-blue;
            text-align: center;
            letter-spacing: 0.02em;
            i {
                color: $secondary-blue;
                display: block;
            }
        }
        .sendMessageButton:hover {
            color: $primary-blue;
            i {
                color: $primary-blue;
            }
        }
    }

}