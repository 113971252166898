.hoursAddButton {
    outline: none;
    appearance: none;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
    cursor: pointer;
    color: rgba(0,0,0,0.5);
    // float: left;
    i {
        line-height: 42px;
    }
}

.hoursAddButton:hover {
    color: rgba(0,0,0,0.8);
}