@import 'styles/vars';

.bodyContainer--schedule {
    display: flex;
    flex-direction: column;
    max-height: 100vh;

    .schedule__mainHeader {
        flex: 0 0 6rem;
        line-height: 5.9rem;
        font-size: 2rem;
        border-bottom: 1px solid #f3f3f3;
        padding: 0 2rem;
        line-height: 59px;
    }
}

.schedule__container {
    background: rgba(255,255,255,0.95);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.schedule__container--week {

    .schedule__header {

        display: grid;
        grid-template-columns: 60px repeat(7, 1fr);

    }

    .schedule__body {
        display: grid;
        grid-template-columns: 60px 1fr;
        .schedule__body__days {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
        }
    }

    .schedule__header {
        text-align: center;
        font-size: 2.2rem;
        letter-spacing: 0.02em;
        padding: 1rem 0;
        flex: 1 1 auto;
        margin-top: 1rem;
        // border-bottom: 1px solid #f3f3f3;
        box-shadow: 
            0 2px 2px 1px rgba(60,64,67,.06)
        ;

        .schedule__day__name {
            font-size: 1rem;
            text-transform: uppercase;
            margin: 0 0 .5rem 0;
            font-weight: bold;
        }

    }

    .schedule__day {
        position: relative;
    }

    .schedule__day--today {
        .schedule__day__name {
            color: $secondary-blue;
        }
        .schedule__day__date {
            background: $secondary-blue;
            color: $primary-white;
            border-radius: 50%;
            width: 42px;
            height: 42px;
            line-height: 42px;
            margin: 0 auto;
            text-align: center;
        }
    }

    .schedule__bodyContainer {
        overflow-y: auto;
        padding: 0 1rem;
    }
    
    .schedule__body {
        position: relative;

        .schedule__day {
            border-left: 1px solid #f3f3f3;
        }

        .schedule__hourLine {
            position: absolute;
            width: 100%;
            font-size: 1rem;
            letter-spacing: 0.02em;
            color: rgba(0,0,0,0.5);
            .hour {
                margin-top: -6px;
                // background: #fff;
                width: 35px;
                float: left;
            }
            .line {
                border-top: 1px solid #f3f3f3;
                margin-left: 35px;
            }
        }

        .schedule__currentTime {
            height: 2px;
            width: 100%;
            background: red;
            position: absolute;
        }
        .schedule__currentTime::before {
            content: '';
            width: 8px;
            height: 8px;
            background: red;
            position: absolute;
            left: -4px;
            top: -3px;
            border-radius: 50%;
        }
    }

    .appointment {
        // background: lighten($primary-blue, 60);
        border-radius: 4px;
        padding: .5rem 1rem;
        left: 1px;
        right: 1px;
        border: 1px solid #fff;
        box-sizing: border-box;
        text-decoration: none;
        color: $primary-black;
        .assignments {
            position: absolute;
            z-index: 1;
            bottom: -.6rem;
            right: -.5rem;
            display: flex;
            .assignment {
                width: 14px;
                height: 14px;
                background: #eee;
                border-radius: 50px;
                border: 1px solid #ccc;
                font-size: .9rem;
                line-height: 14px;
                text-align: center;
                font-weight: bold;
                margin-left: .3rem;
                //   box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
                text-align: center;
            }
          }
    }

    .appointment--NOT_CONFIRMED {
        background: repeating-linear-gradient(45deg, lighten($primary-blue, 60) 0px, lighten($primary-blue, 60) 5px, lighten($primary-blue, 56) 5px, lighten($primary-blue, 56) 10px);
    }

    .appointment--CONFIRMED {
        background: lighten($success-green, 45);
    }
    .appointment--CANCELLED {
        background: lighten($primary-red, 40);
        text-decoration: line-through;
    }

}

.scheduleButton.button__secondary {
    height: 36px;
    box-sizing: border-box;
    padding: 0 1.5rem;
    line-height: 36px;
}

.schedule__previous,
.schedule__next {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    i {
        vertical-align: middle;
        line-height: 36px;
    }
}

.schedule__previous {
    margin-left: 10px;
}
.schedule__next {
    margin-left: -5px;
    margin-right: 20px;
}

.schedule__previous:hover,
.schedule__next:hover {
    background: rgba(0,0,0,0.05);
}

.boardHeader.schedule {
    display: flex;
    align-items: center;
}

.boardStyleSwitcher {
    height: 36px;
    box-sizing: border-box;
    padding: 0 1.5rem;
    line-height: 36px;
    border: 1px solid rgba(0,0,0,0.1);
    color: $primary-black;
    border-radius: 4px;
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    position: relative;
    margin-top: 1rem;
    cursor: pointer;
    i {
        line-height: 36px;
        display: inline-block;
        vertical-align: bottom;
        width: 17px;
        color: rgba(0,0,0,0.5);
    }
    ul {
        z-index: 1;
        position: absolute;
        list-style: none;
        margin: 0;
        padding: 1rem 0;
        background: #fff;
        box-shadow: 
            0 1px 1px 0 rgba(60,64,67,.08),
            0 1px 6px 3px rgba(60,64,67,.16)
        ;
        border-radius: 4px;
        right: 0;
        display: none;
        li {
            padding: 0 2rem;
            white-space: nowrap;
            width: 160px;
            cursor: pointer;
            a {
                display: block;
                text-decoration: none;
                color: rgba(0,0,0,0.75);
            }
            span {
                float: right;
                font-weight: bold;
                color: rgba(0,0,0,0.4);
            }
        }
        li:hover {
            background: rgba(0,0,0,0.05);
        }
        li.active {
            background: rgba(0,0,0,0.05);
        }
    }
}
.boardStyleSwitcher:hover {
    background: rgba(0,0,0,0.05);
}

.appointmentConfirmationStatus {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
    color: $read-only-gray;
}

.appointmentConfirmationStatus--confirmed {
    color: $success-green;
}

.appointmentConfirmationStatus--cancelled {
    color: $primary-red;
}

.bodyContainer--schedule.schedule--day {

    .schedule__header {
        display: flex;
    }

    .schedule__body__days {
        display: flex;
    }

    .schedule__day--week.schedule__day--notToday {
        transform: scale(0);
        overflow: hidden;
    }
    .schedule__day--today {
        width: 100%;
    }
    .schedule__day--day.schedule__day--notToday {
        width: 100%;
    }
}

.calendarSwitcherContainer {

    float: right;
    margin: 0 1rem;

    .calendarStyleSwitcher {
        outline: none;
        appearance: none;
        border: none;
        background: none;
        cursor: pointer;
        line-height: 59px;
        padding: 0 1rem;
    }

    .calendarStyleSwitcher--active {
        color: red;
    }

}