.videoContainer{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
.videoContainer iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.release {
    font-size: 1.6rem;
    i {
        line-height: 16px;
        vertical-align: middle;
    }
}