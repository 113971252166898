@import 'styles/vars';

.statContainerBorder {
    position: relative;
    h3 {
        position: absolute;
        top: 7rem;
    }
}

@media screen and (min-width:$lg) {
    .dashboardReportContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-left: 1px solid #eee;
        border-top: 1px solid #eee;

        // aside {
        //     border-left: 1px solid #fafafa;
        // }

        .statContainer {
            h1 {
                font-size: 1.4rem;
                font-weight: bold;
                margin: 0;
            }
            .statContainerBorder {
                border-right: 1px solid #eee;
                border-bottom: 1px solid #eee;
                padding: 1rem;
                h3 {
                    top: 5rem;
                }
            }
        }

    }
}

@media screen and (min-width: $md) {
    .myDayGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        .card {
            font-size: 4rem;
            color: $secondary-blue;
            .title {
                font-size: 1.7rem;
                color: $primary-black;
                margin-left: 1rem;
                line-height: 4rem;
                vertical-align: middle;
            }
        }
    }
}