/* Variable definitions */
@import "styles/vars";

  .input-container {
    display: block;
    border-radius: 4px;
    margin: 0 0 1.5rem 0;
    position: relative;
    height: 4.5rem;
    padding: 0 1.5rem;
    border: 1px solid rgba(0,0,0,0.15);
  }
  label {
    display: block;
  }
  label .label {
    font-size: 1.4rem;
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    line-height: 4.5rem;
    transition: all 0.1s ease-out;
    color: rgba(0,0,0,0.65);
    text-align: left;
  }

  label .helper {
    position: absolute;
    right: 0;
    transition: all 0.1s ease-out;
    color: rgba(0,0,0,0.65);
  }
  .input-container.input-focus .label,
  .input-container.input-filled .label {
    top: 0px;
    font-size: 1.2rem;
    line-height: 2.4rem;
  }
  // .input-container.input-focus .helper,
  // .input-container.input-filled .helper {
  //   top: 0px;
  //   font-size: .8rem;
  //   line-height: 2.4rem;
  //   transition: all .1s ease;
  // }
  .input-container.input-focus {
    border: 1px solid $primary-blue;
  }

  .input-container.input-alignRight {
    .label {
      right: 1.5rem;
    }
    input {
      text-align: right;
    }
  }

  .required-hint:after {
    content: " *";
    color: #ff0000;
    position: absolute;
    margin: 0px 0px 0px 80%;
    font-size: 1.2em;
    top: .5rem;
    right: .8rem;
  }

  select,
  input {
    position: relative;
    background: none;
    border: none;
    outline: none;
  }

  select {
    top: 2.2rem;
    width: 100%;
    font-size: 1.6rem;
  }
  
  input {
    top: 2.2rem;
    width: 100%;
    font-size: 1.6rem;
  }

  fieldset {

    border: none;
    margin: 0;
    padding: 0;

    legend {

      color: lighten($primary-blue, 20);
      text-align: left;
      font-size: 1.8rem;
      margin-bottom: 1rem;

    }

  }
  
  .autoComplete__container {
    list-style: none;
    padding: 0;
    margin:-1.5rem 0 0 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 240px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    left: 0;

    li {
      padding: 1.5rem;
      border-bottom: 1px solid #eee;
      letter-spacing: 0.02em;
      cursor: pointer;
      
      .autoComplete__customerName {
        font-size: 1.8rem;
      }
      .autoComplete__mobileNumber { font-size: 1.4rem; float: right; }
      .autoComplete__address {
        margin-top: 1rem;
      }
    }

    li:hover {
      background: rgba(0,0,0,0.05);
    }

  }