@import 'styles/vars';

@media screen and (min-width:$lg) {
    .customerGrid {
        display: grid;
        grid-template-columns: auto 320px;

        // aside {
        //     border-left: 1px solid #fafafa;
        // }
    }
}