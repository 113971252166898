@import 'styles/vars';

$focusColor: $primary-blue;

$border: 1px solid rgba(0,0,0,0.1);

.accessCodeContainer {
    // text-align: center
    color: $primary-black; }

.accessCodeLabel {
    display: inline-block; }

.accessCodeHelperText {
    margin: 1rem 0;
    color: $primary-black; }

.accessCodeInput {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    appearance: none; }

.accessCodePart {
    // border: 1px solid lighten($primary-blue,20)
    border: 1px solid rgba(0,0,0,0.15);
    height: 4rem;
    width: 4rem;
    border-radius: $border-radius;
    float: left;
    text-align: center;
    font-size: 2.8rem;
    line-height: 4rem;
    cursor: text; }

.accessCodePart {
    margin-right: 1rem; }

.accessCodePart--focus {
    border-color: $secondary-blue; }

.accessCodePartBlink {
    border-left: 1px solid $primary-blue;
    height: 2.4rem;
    margin-top: .7rem;
    display: inline-block;
    animation: blink 1s step-start infinite; }

@keyframes blink {
    50% {
        opacity: 0; } }

@media screen and (min-width: 64em) {
    .accessCodePart {
        height: 5.4rem;
        width: 5.4rem;
        line-height: 5.4rem; }
    .accessCodePartBlink {
        margin-top: 1.2rem;
        height: 3rem; } }
