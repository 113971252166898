.issue__container {
    .issue__text {
        background: #fafafa;
        border: 1px solid #eee;
        padding: 1.5rem 1rem;
        border-radius: 4px;
        margin-bottom: 1rem;
    }
}

.issue__status {

    color: rgba(0,0,0,0.6);
    padding-bottom: .5rem;
    font-size: 1.2rem;

}

.issue__file {
    display: inline-block;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 1rem;
    box-shadow: 
        0 1px 1px 0 rgba(60,64,67,.08),
        0 1px 3px 1px rgba(60,64,67,.16)
    ;
    margin-bottom: 2rem;
}