@import 'styles/vars';

.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.6);
    z-index: 1;

    .modal {

        min-width: 320px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50.1%);
        background: #fff;
        border-radius: $border-radius;
        padding: 2rem;
        box-shadow: 
            0 1px 1px 0 rgba(60,64,67,.08),
            0 4px 12px 1px rgba(60,64,67,.16)
        ;

        h1 {
            margin-top: 0;
            font-size: 3.6rem;
            margin-bottom: 6rem;
        }

        .modalClose {
            position: absolute;
            right: -22px;
            top: -22px;
            background: #eee;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            text-align: center;
            outline: none;
            appearance: none;
            border: none;
            text-align: center;
            background: $primary-blue;
            color: $primary-white;
            box-shadow: 
                0 1px 1px 0 rgba(60,64,67,.08),
                0 4px 12px 1px rgba(60,64,67,.26)
            ;
            cursor: pointer;
            i {
                line-height: 36px;
            }
        }
        .modalClose:hover {
            background: darken($primary-blue,5);
        }

        .modalHeader {
            border-bottom: 1px solid #fafafa;
            text-align: center;
            padding: 2rem 0;
            margin: -2rem -2rem 0 -2rem;
            border-radius: $border-radius $border-radius 0 0;

            i {
                background: #eee;
                color: $primary-blue;
                padding: 1rem;
                border-radius: 50%;
                margin-bottom: 1.5rem;
            }

        }

        .modalTitleContainer {

            .modalTitle {
                font-size: 1.2rem;
                letter-spacing: .02em;
                color: rgba(0,0,0,0.5);
                margin-bottom: .2rem;
            }
    
            font-size: 1.8rem;

        }
        
    }

    .modal--fullscreen {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translate(0, 0);
        border-radius: 0;
        padding: 0;
        position: relative;
        height: 100%;
        overflow-y: auto;
        .modalClose {
            display: block;
            position: absolute;
            right: 20px;
            // TODO: make this relative to bottom, will break on small screens
            top: 940px;
            background: $primary-blue;
            border: none;
            appearance: none;
            outline: none;
            color: $primary-white;
            text-align: center;
            height: 48px;
            width: 48px;
            cursor: pointer;
            box-shadow: 
                0 1px 1px 0 rgba(60,64,67,.08),
                0 4px 12px 1px rgba(60,64,67,.26)
            ;
        }
        .modalClose:hover {
            background: lighten($primary-blue, 20);
        }
    }

}

.modalButton {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    background: none;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    text-align: center;
    outline: none;
    appearance: none;
    border: none;
    text-align: center;
    color: $primary-white;
    
    z-index: 2;
    cursor: pointer;

    .modalButton__background {
        background: $primary-blue;
        width: 48px;
        height: 48px;
        box-shadow: 
            0 1px 1px 0 rgba(0,0,0,.3),
            0 4px 12px 1px rgba(0,0,0,.6)
        ;
        border-radius: 50%;
    }

    i {
        line-height: 48px;
        text-align: center;
    }
}

.modalButton:hover {
    color: $primary-white;
}
.modalButton__hoverText {
    position: absolute;
    background: $primary-blue;
    white-space: nowrap;
    right: 34px;
    height: 30px;
    padding: 0 2rem;
    line-height: 30px;
    border-radius: 18px 0 0 18px;
    margin-top: 10px;
    width: 0px;
    transition: width 200ms;
    overflow: hidden;
    z-index: -1;
    box-shadow: 
        0 1px 1px 0 rgba(0,0,0,.15),
        0 3px 6px 1px rgba(0,0,0,.3)
    ;
}

.modalButton--open {
    
    i {
        transform: rotate(45deg);
        animation: button-spin-open 200ms ease-in-out;
    }
}
.modalButton--open:hover {
    .modalButton__hoverText {
        width: 130px;
    }
    .modalBackground__button {
        background: darken($primary-blue,5);
        transform: scale(1.1);
    }
    i {
        transform: rotate(45deg);
    }
}

.modalButton--close {
    .modalButton__hoverText {
        width: 0px;
        padding: 0px;
        box-sizing: border-box;
    }
    i {
        animation: button-spin-close 200ms ease-in-out;
    }
}
.modalButton--close:hover {
    .modalButton__background {
        background: darken($primary-blue,5);
        transform: scale(1.1);
    }
}

@keyframes button-spin-open {
    0% { transform: rotate(180deg); }
    100% { transform: rotate(45deg); }
}

@keyframes button-spin-close {
    0% { transform: rotate(45deg); }
    100% { transform: rotate(180deg); }
}

.modalForm {
    width: 50rem;
    position: relative;
}

.modalContainer--print {
    background: #fff;
}

@media screen {
    .modalContainer--print {
        display: none;
    }
}

@media print {
    #root {
        display: none;
    }
    .modalContainer--print {
        display: block;
        position: relative;
    }
}


.modal__header {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    text-align: left;
    padding: 0 2rem;
    font-size: 2rem;
    // background: #fafafa;
    color: $primary-blue;
    top: 0;
    right: 0;
    left: 0;
    height: 61px;
    box-sizing: border-box;

    .modal__closeButton {
        float: right;
        width: 60px;
        height: 60px;
        text-align: center;
        margin-right: -2rem;
        i {
            line-height: 60px;
        }
    }

    .modal__closeButton:hover {
        color: rgba(0,0,0,0.4);
    }

}