@import 'styles/vars';

.contentBox__container {
    padding-top: .5rem;
    margin-bottom: 1rem;
}

.contentBox {
    border-radius: 4px;
    border: 1px solid #eee;
    background: #fafafa;
    padding: 0 1rem;
    position: relative;
}

.contentBox__addButton {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    color: lighten($primary-blue, 30);
    cursor: pointer;
    padding: 1rem 0;
}

.contentBox__editButton {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    position: absolute;
    top: -24px;
    right: -6px;
    display: none;
    cursor: pointer;
    i {
        font-size: 18px;
        color: rgba(0,0,0,0.6);
    }
}

.contentBox__editButton:hover {
    i {
        color: $primary-blue;
    }
}

.contentBox__container:hover {
    .contentBox__editButton {
        display: block;
    }
}

.contentBox__addButton:hover {
    color: lighten($primary-blue, 10);
}

.contentBox__textarea {
    background: none;
    border: none;
    outline: none;
    appearance: none;
    width: 100%;
    display: block;
    padding: 1rem 0;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.4rem;
    position: relative;
    resize: vertical;
}

.contentBox__placeholder {
    position: absolute;
    left: 1rem;
    top: 1rem;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.4rem;
    color: rgba(0,0,0,0.7);
}

.contentBox__status {

    color: rgba(0,0,0,0.6);
    padding-bottom: .5rem;

}

.contentBox__actions {
    text-align: right;
    margin-top: 1rem;
    .contentBox__cancelButton,
    .contentBox__saveButton {
        outline: none;
        appearance: none;
        background: none;
        border: 1px solid #ccc;
        padding: .3rem 1.5rem;
        border-radius: 4px;
        cursor: pointer;
    }

    .contentBox__saveButton {
        background: $success-green;
        border-color: darken($success-green, 10);
        color: $primary-white;
    }

}