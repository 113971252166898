@import 'styles/vars';

.action__container {

    display: grid;
    grid-gap: 1rem;
    position: relative;
    
    i {
        // color: red;
    }

    .action__inputContainer {
        background: #fafafa;
        border-radius: 4px;
        padding: .5rem 1rem;
        position: relative;
        
        input {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
        }

    }

    // .react-calendar {
    //     position: absolute;
    //     right: 0;
    //     top: 40px;
    //     max-width: unset;
    // }

}

.action__dateContainer {
    display: block;
}