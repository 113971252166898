@import 'styles/vars';

.technicianFilter {

    float: right;
    margin-top: -2px;
    margin-right: .5rem;

    .technician {
        width: 36px;
        height: 36px;
        background: #eee;
        border-radius: 50%;
        line-height: 36px;
        margin-right: .5rem;
        font-weight: bold;
        border: 2px solid #eee;
    }

    .technician:hover {
        border-color:#ccc;
    }

    .technician.technician--active {
        border-color: $secondary-blue;
    }

}