@import 'styles/vars';

.subNavContainer {

    margin: 1rem 0;

    a {
        display: inline-block;
        padding: 1rem 0;
        margin-right: 3rem;
        text-decoration: none;
        letter-spacing: 0.02em;
        color: rgba(0,0,0,0.45);
        font-size: 1.3rem;
    }

    a.active {
        color: $primary-blue;
        border-bottom: 2px solid $primary-blue;
    }

}