.subscriptionNoticeWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.6);
    z-index: 999999999;

    .subscriptionNoticeContainer {
        background: #fff;
        padding: 8rem;
        box-shadow: 
            0 1px 1px 0 rgba(60,64,67,.18),
            0 1px 12px 1px rgba(60,64,67,.36)
        ;
        border-radius: 4px;
        text-align: center;
    }

}