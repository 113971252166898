@import 'styles/vars';

// .noData__container {

//     text-align: center;
//     border-radius: 4px;
//     background: #eee;
//     padding: 2rem;

//     h2 {
//         margin: 0 0 2rem 0;
//         font-size: 1.6rem;
//     }

// }

.noData__container {
    text-align: center;
    color: $primary-blue;
    font-size: 2rem;
    margin: 8rem 0;
    background: #fafafa;
    border-radius: 4px;
    padding: 4rem;
    border: 1px solid #eee;

    .button {
        margin-top: 3rem;
    }
}

.noData__container--small {
    margin: 0 auto;
    padding: 2rem;
    font-size: 1.4rem;
}