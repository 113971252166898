@import 'vars';
// @import url('https://fonts.googleapis.com/css?family=Roboto');
// @import url('https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* The emerging W3C standard
   that is currently Firefox-only */
* {
    scrollbar-width: thin;
    scrollbar-color: $primary-blue #eee;
    -webkit-font-smoothing: antialiased;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    background: #eee;
}
*::-webkit-scrollbar-thumb {
    background-color: $primary-blue;
    border-radius: 3px;
    border: 1px solid $primary-blue;
}

html {
  font-size: 62.5%;
}

root, html, body, #root {
    height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #fff;
  color: $primary-black;
}

.wrapper {
    height: 100%;
}

.wrapper--blur {
    filter: blur(1px);
}

.header {
    background: $primary-blue;
}

.container {
    // overflow-y: auto;
}

.bodyContainer {
    padding: 2rem;
    max-width: 120rem;
    margin: 0 auto;
    // overflow-y: auto;
}

.bodyContainer--fullWidth {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    color: $primary-blue;
}

a {
    color: $secondary-blue;
    text-decoration: none;
}

a:hover {
    color: darken($secondary-blue, 10);
}

button {
    outline: none;
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}

h1 {
    font-size: 3rem;
    color: $primary-blue;
    a {
        text-decoration: none;
        color: $primary-blue;
    }
    a:hover {
        color: darken($primary-blue, 10);
    }
    i {
        line-height: 33px;
        vertical-align: middle;
        margin: 0 1rem;
        font-size: 2rem;
    }
    .current {
        color: $secondary-blue;
    }
}

h2 {
    color: $secondary-blue;
}

h6 {
    font-size: 1.2rem;
    font-weight: bold;
    // font-family: Roboto;
    // font-weight: semi-bold;
    letter-spacing: 0.02em;
    color: rgba(0,0,0,0.5);
    margin-top: -4rem;
    margin-bottom: 4rem;
}

@media screen and (min-width:64em) {
    .wrapper {
        display: grid;
        grid-template-columns: 8rem auto;
    }
}

// Screen sizes
// sm min-width: 35.5em
// md min-width: 48em
// lg min-width: 64em
// xl min-width: 80em