@import 'styles/vars';

.linkDevice__code {
    text-align: center;
    font-size: 1.4rem;
    color: rgba(0,0,0,0.8);
    
    .code {
        display: inline-block;
        font-size: 6rem;
        margin: 2rem 0;
        word-spacing: 0.3em;
        font-family: Times, 'Times New Roman', serif;
    }
    span {
        color: $secondary-blue
    }
}

.loadingContainer {
    text-align: center;
    .spinner i {
        font-size: 48px;
        color: rgba(0,0,0,0.3);
        animation: spin 1.2s infinite;
    }
}

@keyframes spin { 100% { transform: rotate(360deg); } }