@import 'styles/vars';

.surveyContainer {
    text-align: center;

    h2 {
        color: $primary-blue;
        margin-bottom: 4rem;
    }

    .cesSurvey {

        .surveyButton {
            outline: none;
            appearance: none;
            border: 1px solid $secondary-blue;
            background: none;
            padding: 1rem;
            border-radius: 4px;
            width: 100px;
            margin: 0 .5rem;
            cursor: pointer;
        }
        .surveyButton:hover {
            background: $secondary-blue;
            color: $primary-white;
        }
    }

}