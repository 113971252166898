.estimate__container {
    // width: 180px;
    // height: 320px;
}

.estimate__card {
    position: relative;
    // height: 100%;
    box-shadow: 
        0 1px 1px 0 rgba(60,64,67,.08),
        0 1px 3px 1px rgba(60,64,67,.16)
    ;
    border-radius: 4px;
    overflow: hidden;
}

.estimate__amounts {
    text-align: right;
    padding: .5rem 0;
    font-weight: bold;
}