@import 'styles/vars';

aside {
    padding: 0 2rem;
    text-align: center;
}

.asideDetail {

    margin: 5rem 0;

    .asideDetail__title {
        font-size: 1rem;
        letter-spacing: 0.02em;
        color: $secondary-blue;
        text-transform: uppercase;
        margin-bottom: 0.4rem;
        font-weight: bold;
    }

    .asideDetail__value {
        font-size: 2.4rem;
    }

}

@media screen and (max-width: $md) {
    .asideDetail {
        display: inline-block;
        margin: 5rem 2.5rem;
    }
}