@import 'styles/vars';

.tooltip__container {
    position: relative;
    display: inline-block;
    height: 14px;
}

.tooltip__button {
    position: absolute;
    outline: none;
    appearance: none;
    margin: 0px;
    padding: 0;
    background: none;
    border: none;
    // display: inline-block;
    cursor: pointer;

    .tooltip__icon {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        color: $primary-white;
        background: $primary-blue;
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 14px;
        text-align: center;
    }

}

.tooltip__button:hover {
    .tooltip__icon {
        background: lighten($primary-blue, 20);
    }
}

.tooltip__expander {
    display: none;
    position: absolute;
    left: 14px;
    top: 14px;
    z-index: 1;
    background: #fafafa;
    padding: 2rem;
    box-shadow: 
      0 1px 1px 0 rgba(60,64,67,.08),
      0 2px 12px 1px rgba(60,64,67,.16)
    ;
    border-radius: 4px;
    border: 1px solid #eee;
    font-size: 1.4rem;
    font-weight: normal;
    min-width: 300px;
}

.tooltip__expander--expanded {
    display: block;
}