@import 'styles/vars';

.boardContainer {
  background: #f3f3f3;
  display: grid;
  grid-template-rows: 6rem 1fr;
  grid-gap: 1px;
  height: 100%;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: $primary-blue;
    letter-spacing: 0.02em;
  }

  h2 span {
    float: right;
    font-size: 1.4rem;
    color: $read-only-gray;
  }

  .boardHeader {
    background: $primary-white;
    padding: 0 2rem;
    line-height: 6rem;
    font-size: 2rem;
  }

  .boardColumns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1px;
  }

  .boardSection {
    background: $primary-white;
    padding: 2rem;
    overflow-y: auto;
  }

  .boardCard {
    border-radius: 4px;
    box-shadow: 
        0 1px 1px 0 rgba(60,64,67,.08),
        0 1px 3px 1px rgba(60,64,67,.16)
    ;
    padding: 1rem;
    margin: 1rem 0;
    font-size: 1.4rem;
    
    letter-spacing: 0.02em;
    width: 100%;
    appearance: none;
    outline: none;
    background: none;
    border: none;
    text-align: unset;
    background: #fff;
  }

  button.boardCard {
    cursor: pointer;
  }

  .boardCard:hover {
    box-shadow: 
      0 1px 1px 0 rgba(60,64,67,.08),
      0 2px 12px 1px rgba(60,64,67,.16)
    ;
  }

  .boardCard__customerName {
    padding: 0 0 .8rem 0;
  }

  .boardCard__customerName {
    font-size: 1.8rem;
  }

  .boardCard--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    .boardCard__container {
      display: none;
      transition: all 2s linear;
    }
  }

}

.addButton {
  width: 4.8rem;
  height: 4.8rem;
  padding: 0px;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  border-radius: 50%;
  box-shadow: 
      0 1px 1px 0 rgba(60,64,67,.18),
      0 4px 8px 1px rgba(60,64,67,.46)
  ;
  text-align: center;
  i {
      line-height: 4.8rem;
      margin: 0px;
  }
  border: none;
  background: $primary-blue;
  outline: none;
  appearance: none;
  color: $primary-white;
  cursor: pointer;
}
.addButton:hover {
  background: darken($primary-blue, 5);
}

.boardStyleContainer {
  float: right;
  button {
    font-size: .9rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  i {
    color: black;
    display: block;
  }
}

.boardCard__container {
  .assignments {
    position: absolute;
    bottom: -.6rem;
    right: -.5rem;
    display: flex;
    .assignment {
      width: 18px;
      height: 18px;
      background: #ddd;
      border-radius: 50px;
      border: 1px solid #ddd;
      font-size: 1rem;
      line-height: 18px;
      text-align: center;
      font-weight: bold;
      margin-left: .3rem;
      box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    }
  }
}

@media screen and (min-width:103em) {
  .boardCard__container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .boardCard__customerPhone,
  .boardCard__statusContainer {
    // font-weight: bold;
    text-align: right;
  }
}