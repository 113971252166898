@import 'styles/vars';

.login__container {
    display: flex;
    align-items: stretch;
    height: 100vh;

    .login__sidebar {
        flex: 33.333%;
        background: $primary-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-white;
        
        .logo {
            text-align: center;
            margin-bottom: 4rem;
            i {
                font-size: 96px;
            }
        }

        ul {
            list-style: none;
            // padding: 0;
            // margin: 0;
            margin: 0;
            padding: 0 2rem 0 5rem;
            max-width: 300px;

            li {
                position: relative;
                margin-top: 6rem;
                color: lighten($primary-blue, 50);
                font-size: 1.4rem;
                // max-width: 260px;
                h2 {
                    color: $primary-white;
                    font-size: 1.6rem;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    margin-bottom: .7rem;
                }
                i {
                    font-size: 24px;
                    position: absolute;
                    left: -3.2rem;
                    color: lighten($primary-blue, 30);

                }
            }
        }
    }

    .login__body {

        padding: 2rem 0;
        
    // overflow-y: auto;

        .logo {
            text-align: center;
            margin-bottom: 4rem;
            color: $primary-blue;
            i {
                font-size: 96px;
            }
        }

        .forgotPasswordLink {
            float: right;
        }

        .loginSignupSwitch {
            text-align: center;
            margin-top: 2rem;
        }

    }

    .passwordTest {
        font-size: 1.2rem;
        margin-bottom: .5rem;
        color: rgba(0,0,0,0.7);
        line-height: 14px;
        font-weight: bold;
        i {
            font-size: 14px;
            font-weight: bold;
            color: rgba(0,0,0,0.2);
            vertical-align: middle;
        }
    }

    .passwordTest--passed {
        color: $success-green;
        i {
            color: $success-green;
        }
    }

    .deviceDescriptorContainer {
        text-align: center;
    }
    .deviceDescriptor {
        text-align: center;
        font-weight: bold;
        border: 1px solid #eee;
        display: inline-block;
        padding: 1rem 2rem;
        border-radius: 4px;
        background: #fafafa;
    }

    .languageSwitcher {
        outline: none;
        appearance: none;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        padding: 1rem 0;
        color: $secondary-blue;
        cursor: pointer;
    }

    .languageSwitcher:hover {
        color: darken($secondary-blue, 10);
    }

}

@media screen and (max-width:63.999em) {

    .login__container {
        padding: 0 2rem;
    }
    .login__body {
        width: 100%;
    }
    .login {
        width: 100%;
    }
    .login__sidebar {
        display: none !important;
    }
}

@media screen and (min-width:64em) {
    .login {
        width: 480px;
    }

    .login__body {
        display: flex;
        flex: 66.666%;
        justify-content: center;
        align-items: center;
    }
}