.technicianAssignmentContainer {
    border: 1px solid #eee;
    background: #fafafa;
    border-radius: 4px;
    position: relative;
    height: 38px;
    margin-bottom: 1rem;

    label {
        line-height: 40px;
        position: absolute;
        top: 0;
        left: 1rem;
    }

    input {
        text-indent: 1rem;
        position: absolute;
        top: 0px;
        bottom: 0;
        line-height: 40px;
        padding: 0;
    }

    ul {
        list-style: none;
        background: #fafafa;
        box-shadow: 
            0 1px 1px 0 rgba(60,64,67,.08),
            0 1px 3px 1px rgba(60,64,67,.16)
        ;
        border-radius: 2px;
        position: absolute;
        top: 40px;
        margin: 0;
        width: 100%;
        padding: 0;

        li {
            button {
                outline: none;
                appearance: none;
                border: none;
                background: none;
                display: flex;
                padding: 1rem;
                width: 100%;
                cursor: pointer;
                line-height: 24px;
                i {
                    opacity: 0;
                    margin-right: .5rem;
                }
                div.initials {
                    background: rgba(0,0,0,0.1);
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    margin-right: 1rem;
                    font-size: 1rem;
                }
            }
            button:hover {
                background: rgba(0,0,0,0.1);
            }
            button.assigned {
                i {
                    opacity: 1;
                }
            }
        }

    }

}

.technicianAssignmentContainer--filled {
    label {
        top: -10px;
    }
    input {
        bottom: -16px;
    }
}

.assignedToLabel {
    background: #eee;
    display: inline-block;
    padding: .5rem 1rem;
    border-radius: 50vh;
    margin-right: .5rem;
}