@import 'styles/vars';

.appointment__container {
    height: 100vh;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    
    // grid-template-columns: auto-fit auto;

    .appointment__sidebarContainer {
        background: $primary-blue;
        flex: 0 0 0;
        transition: 200ms;
        text-align: center;
        color: $primary-white;
        
        display: flex;
        flex-direction: column;
        letter-spacing: 0.02em;

        // .appointment__customerContainer {
        //     flex-grow: 1;
        //     justify-content: center;
        //     align-items: center;
        //     display: flex;
        //     flex-direction: column;
        // }
        // .appointment__vehicleContainer {
        //     flex-grow: 1;
        //     justify-content: center;
        //     align-items: center;
        //     display: flex;
        //     flex-direction: column;
        // }

        .appointment__customerContainer {
            margin-top: 6rem;
        }

        .appointment__customerName {
            font-size: 3.2rem;
        }

        .appointment__customerPhone {
            font-size: 1.8rem;
            margin-top: 2rem;
            color: rgba(255,255,255,0.75);
        }

        .appointment__customerAddress {
            margin-top: 2rem;
            font-size: 1.4rem;
            color: rgba(255,255,255,0.75);
        }

    }

    .appointment__bodyContainer {

        text-align: center;
        padding: 4rem;
        flex: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // overflow-y: auto;
    }

    .appointment__bodyScrollContainer {
        overflow-y: auto;
        height: calc(100vh - 61px);
    }

    .appointment__header {
        border-bottom: 1px solid rgba(0,0,0,0.05);
        text-align: left;
        padding: 0 2rem;
        font-size: 2rem;
        // background: #fafafa;
        color: $primary-blue;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 61px;
        box-sizing: border-box;

        h1 {
            display: inline;
            font-size: 2.6rem;
            line-height: 60px;
        }

        .appointment__closeButton {
            float: right;
            width: 60px;
            height: 60px;
            text-align: center;
            margin-right: -2rem;
            i {
                line-height: 60px;
            }
        }

        .appointment__closeButton:hover {
            color: rgba(0,0,0,0.4);
        }

    }

    .appointment__bodyContainer--top {
        display: block;
        padding: 0;
        text-align: left;
        .appointment__header {
            position: relative;
        }
    }

    .upload__container {
        min-height: 220px;
    }

    .addVehicleButton {
        outline: none;
        appearance: none;
        border: none;
        background: none;
        color: lighten($primary-blue,20);
        line-height: 24px;
        text-decoration: underline;
        padding: 1rem;
        margin-top: 3rem;
        cursor: pointer;
        i {
            vertical-align: middle;
            margin-right: .5rem;
        }
    }

    .addVehicleButton:hover {
        color: #666;
    }

}

.appointment__container.appointment__container--expandedSidebar {
   .appointment__sidebarContainer {
       flex: 0 0 24em;
       transition: 250ms;
       box-shadow: 
            1px 0 1px 0 rgba(60,64,67,.08),
            1px 0 3px 1px rgba(60,64,67,.16)
        ;
   }
}

.appointment__vehicleContainer {
    // margin-top: 10rem;
    overflow: hidden;
}

.appointment__vehiclePicture {
    
    // min-height: 320px;
    // border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    // box-shadow: 
    //     0 1px 1px 0 rgba(0,0,0,.08),
    //     0 1px 3px 1px rgba(0,0,0,.16)
    // ;
    

    i {
        font-size: 96px;
        color: darken($primary-blue, 5);
        line-height: 165px;
    }

}

.appointment__vehicleString {
    font-size: 2rem;
    margin-top: 2rem;
}

.timeSelector__container {
    overflow-y: scroll;
    max-height: 600px;
}

.timeSelector__selectedDate {
    margin: 0 auto;
    width: 220px;
    padding-bottom: 2rem;
    line-height: 24px;
    .timeSelector__clearDateButton {
        float: right;
    }
}

.timeButton__container {
    margin: 0 auto;
    width: 220px;
    display: flex;
    margin-bottom: 1rem;
}


.timeButton__buttonContainer {
    width: 100%;
}
.timeButton__confirmButtonContainer {
    width: 0%;
}

.timeButton__buttonContainer,
.timeButton__confirmButtonContainer {
    transition: width 200ms;
}

.timeButton__padding {
    padding: 0 .2rem;
}

.timeButton {
    display: block;
    padding: 1rem 0;
    border: 1px solid lighten($primary-blue,20);
    color: lighten($primary-blue, 20);
    background: none;
    border-radius: 4px;
    transition: width 200ms;
    width: 100%;
    outline: none;
    appearance: none;
    cursor: pointer;
}

.timeButton__confirm {
    display: block;
    padding: 1rem 0;
    background: lighten($primary-blue,20);
    color: $primary-white;
    border-radius: 4px;
    border: none;
    width: 100%;
    transition: width 200ms;
    outline: none;
    appearance: none;
    cursor: pointer;
}

.timeButton__container.timeButton--confirm {

    .timeButton__buttonContainer {
        width: 50%;
        transition: width 200ms;

        .timeButton {
            background: rgba(0,0,0,.8);
            color: $primary-white;
            border-color: rgba(0,0,0,.8);
        }

    }

    .timeButton__confirmButtonContainer {
        width: 50%;
        transition: width 200ms;
        .timeButton__confirm {
            border: 1px solid lighten($primary-blue, 20);
        }
    }
}

.calendar {

    max-width: 480px;

    button {
        appearance: none;
        outline: none;
        border: none;
        background: none;
    }

    .react-calendar {

        border: none;

    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
        display: none;
    }

    .react-calendar__navigation__arrow {
        background: rgba(0,0,0,0.2);
        border-radius: 50%;
        width:15px;
    }

    .react-calendar__month-view__weekdays__weekday {
        text-transform: uppercase;
        padding: 2rem;
        abbr {
            text-decoration: none;
        }
    }

    .react-calendar__tile {
        padding: 1rem;
        position: relative;
        font-size: 1.6rem;
        color: rgba(0,0,0,0.75);
        cursor: pointer;
        abbr {
            background: #f3f3f3;
            border-radius: 50%;
            display: block;
            line-height: 36px;
            width:36px;
            height: 36px;
            margin: 0 auto;
        }
    }

    .react-calendar__tile:disabled {
        color: rgba(0,0,0,0.4);
        cursor:auto;
        abbr {
            background: none;
        }
    }

    .react-calendar__tile--now::after {
        content: '•';
        position: absolute;
        font-size: 18px;
        bottom: 5px;
        left: 50%;
        margin-left: -3px;
        color: lighten($primary-blue, 20);
    }

    .react-calendar__tile--active {
        abbr {
            background-color: lighten($primary-blue, 20);
            color: $primary-white;
        }
    }

    .react-calendar__tile--now.react-calendar__tile--active::after {
        color: $primary-white;
    }

}

// TODO: need to finish this
.customerActionContainer {
    // margin-top: 1rem;
    float: right;
    font-size: 1.6rem;
    line-height: 60px;
    form {
        margin-top: 1rem;
    }
    label {
        display: inline-block;
        min-width: 280px;
    }
    .submitButton {
        display: inline-block;
        float: right;
        margin-left: 1rem;
    }
}

.actionNotificationContainer {
    float: right;
    height: 60px;
    width: 40px;
    text-align: center;
    position: relative;
    cursor: pointer;
    i {
        line-height: 60px;
    }
    .actionNotificationHover {
        display: none;
        text-align: left;
    }
}

.actionNotificationContainer:hover {
    .actionNotificationHover {
        display: block;
        position: absolute;
        z-index: 1;
        min-width: 360px;
        background: #fff;
        right: 35px;
        top: 35px;
    }
}

.appointmentTimeEditButton {
    border:none;
    outline: none;
    appearance: none;
    height: 30px;
    vertical-align: middle;
    width: 30px;
    background: #eee;
    text-align: center;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    margin-left: 1rem;
    cursor: pointer;
    i {
        vertical-align: middle;
        line-height: 30px;
        margin: 0;
        color: rgba(0,0,0,.6);
        font-size: 18px;
    }
}

.appointmentTimeEditButton:hover {
    background: darken(#eee, 10);
}

@media screen and (max-width:80em) {
    .appointmentBooking__container--dateIsSet {
        .calendar {
            display: none;
        }
    }
}

@media screen and (min-width:80em) {
    .appointment__container.appointment__container--expandedSidebar {
        .appointment__sidebarContainer {
            flex: 0 0 36em;
        }
     }
     .appointmentBooking__container--dateIsSet {
         display: grid;
         grid-template-columns: 1fr 1fr;
     }

}