@import 'styles/vars';

$accordion-border: 1px solid #eee;

.accordionContainer {
    margin: 0 -2rem -2rem -2rem;

    .accordionItem {
        border-top: $accordion-border;
        padding: 2rem;
        position: relative;
    }

    .accordionItem--active {
        background: rgb(239, 246, 255);
    }    

    .accordionItem__title {

        font-weight: bold;
        letter-spacing: 0.02em;
        cursor: pointer;
        position: relative;
        padding: 2rem;
        margin: -2rem;
        color: $secondary-blue;

        i {
            transition: transform 100ms ease-in-out;
            line-height: 16px;
            vertical-align: bottom;
            margin-right: 1rem;
            color: rgba(0,0,0, 0.3);
        }
    }

    .accordionItem__title:not(.accordionItem__title--complete):hover {
        color: $primary-blue;
    }

    .accordionItem__title--active {
        padding: 0;
        margin: 0;
        display: inline-block;
    }

    .accordionItem__title--active:hover {
        background: none;
    }

    .accordionItem__title--active i {
        transform: rotate(90deg);
        transition: transform 100ms ease-in-out;
    }

    .accordionItem__title--complete {
        cursor: auto;
        color: $primary-black;
        i {
            color: $success-green;
        }
    } 

    .accordionItem__text {
        margin-left: 3.3rem;
        margin-top: 1rem;
    }

    .accordionButton {
        text-decoration: none;
        border-radius: 4px;
        background: lighten($primary-blue, 20);
        color: $primary-white;
        display: inline-block;
        padding: .7rem 1rem;
        margin-top: 1rem;
        appearance: none;
        outline: none;
        border: none;
        cursor: pointer;
    }

    
    .accordionButton:hover {
        background: lighten($primary-blue, 10);
    }
    
    .accordionButton:disabled {
        background: rgba(0,0,0,0.4);
        color: rgba(255, 255, 255, 0.8);
        cursor: auto;
    }
}