@import 'styles/vars';

.npsRating {
    font-size: 3rem;
}

.npsRating--promoter {
    color: $success-green;
}

.npsRating--passive {
    color: $primary-blue;
}

.npsRating--detractor {
    color: $primary-red;
}

.npsRating--na {
    color: rgba(0,0,0,0.4);
    font-size: 2rem;
}