@import 'styles/vars';

.onboardingSteps {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 33.2px;

    li {
        
        
        a {
            display: block;
            border-left: 2px solid rgba(0,0,0,0.2);
            padding: .5rem 0;
            text-indent: 1rem;
            font-size: 1.4rem;
            font-weight: bold;
            letter-spacing: 0.02em;
            color: rgba(0,0,0,0.5);
            text-decoration: none;
        }
    }

    li a.active {
        border-color: $secondary-blue;
        color: $secondary-blue;
    }
    
}

.onboarding__header {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    text-align: left;
    padding: 0 2rem;
    font-size: 2rem;
    // background: #fafafa;
    color: $primary-blue;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    height: 61px;
    box-sizing: border-box;

    .onboarding__closeButton {
        float: right;
        width: 60px;
        height: 60px;
        text-align: center;
        margin-right: -2rem;
        i {
            line-height: 60px;
        }
    }

    .onboarding__closeButton:hover {
        color: rgba(0,0,0,0.4);
    }

}