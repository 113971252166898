@import 'styles/vars';

.upload__dropzone {
    text-align: center;
    border: 1px dashed #ccc;
    border-radius: 4px;
    font-size: 1.3rem;
    padding: 1rem 0;
    cursor: pointer;
}

.upload__dropzone:hover {
    border-color: $primary-blue;
}

// .fileList__container {
    // max-height: 20rem;
    // overflow-y: auto;
// }

.fileList__fileContainer {

    background: #eee;
    padding: 1rem;
    border-radius: 4px;
    margin: 1rem 0;
    position: relative;

}

.fileList__filename {
    display: inline-block;
    line-height: 4em;
    font-size: 9pt;
    margin-left: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 48px);
}

.fileList__image {
    display: inline-block;
    vertical-align: top;
    width: 4em;
}

.fileList__progressContainer{

    position: relative;
    width: 4em;
    height: 4em;
    background: rgba(255,255,255,0.9);
    border-radius: 50%;

    .fileList__dialContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        overflow: hidden;
        width: 50%;
        height: 100%;

        .fileList__wedge {
            background: $primary-blue;
            height: 100%;
            width: 100%;
        }
    }

    .fileList__dialContainer--container1 {
        left: 50%;

        .fileList__wedge {
            border-radius: 0 4em 4em 0;
            left: 0;
            transform-origin: 0 50%;
            transform: rotateZ(-180deg);
        }

    }

    .fileList__dialContainer--container2 {
        left: 0;

        .fileList__wedge {
            border-radius: 4em 0 0 4em;
            transform-origin: 2em 2em;
            transform: rotateZ(-180deg);
        }
    }
}

.fileList__progressContainer::after {
    content: "";
    background: #eee;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    position: absolute;
    top: 0.5em;
    left: 0.5em;
  }

.fileList__delete {
    border-radius: 50%;
    background: rgba(0,0,0,0.2);
    border: none;
    position: absolute;
    right: 1rem;
    top: 50%;
    padding:4px;
    width: 36px;
    transform: translateY(-50%);
    outline: none;
    cursor: pointer;
}

.fileList__delete:hover {
    background: rgba(0,0,0,0.3);
}

// $keyframes rotate-bg-1 {
//     50%, 100% {
//       transform: rotateZ(0deg);
//     }
//   }
//   /* Second animation moves 180 degrees in the last 2 seconds */
//   $keyframes rotate-bg-2 {
//     0%, 50% {
//         transform: rotateZ(-180deg);
//     }
//     100% {
//       transform: rotateZ(0deg);
//     }
//   }

.fileListLink {
    color: $secondary-blue;
}
.fileListLink:hover {
    color: lighten($secondary-blue, 20);
}