@import 'styles/vars';

.labelPill {

    border-radius: 3px;
    background: $read-only-gray;
    display: inline-block;
    width: 80px;
    text-align: center;
    font-size: .9rem;
    letter-spacing: 0.02em;
    padding: .3rem 0;
    color: $primary-white;
    text-transform: uppercase;

  }

.labelPill--large {
    padding: .5rem 2rem;
    width: auto;
    font-size: 1.4rem;
}

  // UNLISTED STATUSES DEFAULT TO GREY background-color

  .labelPill--diagnosis,
  .labelPill--work_started {
    background-color: $primary-yellow;
  }

  .labelPill--appointment_request,
  .labelPill--identified,
  .labelPill--arrival_pickup,
  .labelPill--departure_drop {
    background-color: $primary-blue;
  }

  .labelPill--arrival_pickup_complete,
  .labelPill--arrival_drop_complete,
  .labelPill--customer_approved,
  .labelPill--complete,
  .labelPill--standard_service,
  .labelPill--departure_drop_complete,
  .labelPill--departure_pickup_complete {
    background-color: $success-green;
  }

  .labelPill--customer_declined,
  .labelPill--cancelled {
    background-color: $primary-red;
  }